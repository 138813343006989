import * as actionTypes from '../actions/actionTypes';
import * as resultActions from './helper/sharedReturnedActions';

const initialState = {
    medias : null,
    error: {},
    loading :{} 
};



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_MEDIAS_STARTED : return resultActions.startedHandler( state, action );
        case actionTypes.GET_MEDIAS_SUCCESS : return resultActions.successHandler( state, action );
        case actionTypes.GET_MEDIAS_FAILURE : return resultActions.failureHandler( state, action );
        default: return state;
    }
};

export default reducer;