import * as actionTypes from './actionTypes';

export const getMediasLinks = () => {
    return {
        type: actionTypes.GET_MEDIAS_LIST
    }
}

export const getMediasSuccess = ( target, responseData ) => {
    return {
        type: actionTypes.GET_MEDIAS_SUCCESS,
        target: target,
        responseData : responseData
    }
}

export const getMediasStarted = ( target ) => {
    return {
        type: actionTypes.GET_MEDIAS_STARTED,
        target: target
    }
}

export const getMediasFailure = ( target ) => {
    return {
        type: actionTypes.GET_MEDIAS_FAILURE,
        target : target
    }
}