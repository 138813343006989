exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Layout__Layout__2C2_c {\n    width: 100%;\n    color: white;\n}\n\n.Layout__Content__3pLYC {\n    margin-left: 400px;\n    width: calc(100% - 400px);\n}\n\n.Layout__ToTopButton__2wCiH {\n    position: fixed;\n    right: 20px;\n    bottom: 20px;\n    opacity: 0;\n    background: rgba(0,0,0,0.7);\n    font-size: 20px;\n    font-weight: 600;\n    height: auto !important;\n}\n\n.Layout__ToTopButtonActive__28ciq {\n    z-index: 1000;\n    opacity: 1;\n    -webkit-transition: opacity .3s ease-out;\n    transition: opacity .3s ease-out;\n}\n\n@media (max-width: 1440px){\n    .Layout__Content__3pLYC {\n        margin : 0;\n        width: 100%;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Layout": "Layout__Layout__2C2_c",
	"Content": "Layout__Content__3pLYC",
	"ToTopButton": "Layout__ToTopButton__2wCiH",
	"ToTopButtonActive": "Layout__ToTopButtonActive__28ciq"
};