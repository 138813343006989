import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import Language from '../../UI/Button/Language/Language';
import Social from '../../Social/Social';
import classes from './SideDrawer.css';

import Logo from '../../../assets/images/Logo.png';
import bgImage from '../../../assets/images/bgImage.jpg';

const SideDrawer = ( props ) => {
    let opened = null;

    if (props.open) {
        opened = classes.Open;
    }

    return (
        <div className={classes.SideDrawer + ' ' + opened}>

            <div className={classes.Wrapper}>
                
                <div className={classes.Overlay}>
                    <img src={bgImage} alt=''/>
                </div>

                <div className={classes.Logo}>
                    <img src={Logo} alt=''/>
                </div>

                <nav className={classes.SideNav}>
                    <NavigationItems color="white" callBack={props.closed} messages={props.messages} />
                </nav>

                <div className={classes.Footer}>
                    <Language className={classes.Language}/>
                    <Social type='email'>andysorrenti@yahoo.it</Social>
                    {/* <Social type='phone'>+39 3393796716</Social> */}
                    <p>Copyright © {new Date().getFullYear()} Andrea Sorrentino. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
};

export default SideDrawer;