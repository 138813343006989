import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import * as resultActions from './helper/sharedReturnedActions';

const initialState = {
    language: 'en',
    email: null,
    error: {},
    loading: {}
};

const setLanguage = ( state, action ) => {
    return updateObject( state, {language: action.language});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_LANGUAGE : return setLanguage ( state, action );
        case actionTypes.SEND_EMAIL_STARTED : return resultActions.startedHandler( state, action );
        case actionTypes.SEND_EMAIL_SUCCESS : return resultActions.successHandler( state, action );
        case actionTypes.SEND_EMAIL_FAILURE : return resultActions.failureHandler( state, action );
        default: return state;
    }
};

export default reducer;