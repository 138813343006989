import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import classes from './TransitionWrapper.css';

const transitionWrapper = props => {   

    return (
        <TransitionGroup className={classes.Wrapper}>
            <CSSTransition
                key={props.location.pathname}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive
                }}
                >
                    <div className={classes.Inner}>{props.children}</div>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default transitionWrapper;