export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_STARTED = 'SEND_EMAIL_STARTED';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const GET_MEDIAS_LIST = 'GET_MEDIAS_LIST';
export const GET_MEDIAS_STARTED = 'GET_MEDIAS_STARTED';
export const GET_MEDIAS_SUCCESS = 'GET_MEDIAS_SUCCESS';
export const GET_MEDIAS_FAILURE = 'GET_MEDIAS_FAILURE';

