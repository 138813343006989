import * as actionTypes from './actionTypes';

export const setLanguage = ( language ) => {
    return {
        type: actionTypes.SET_LANGUAGE,
        language : language
    }
}

export const sendEmail = ( data ) => {
    return {
        type: actionTypes.SEND_EMAIL,
        data : data
    }
}

export const sendEmailSuccess = ( target, responseData ) => {
    return {
        type: actionTypes.SEND_EMAIL_SUCCESS,
        target: target,
        responseData : responseData
    }
}

export const sendEmailStarted = ( target ) => {
    return {
        type: actionTypes.SEND_EMAIL_STARTED,
        target: target
    }
}

export const sendEmailFailure = ( target ) => {
    return {
        type: actionTypes.SEND_EMAIL_FAILURE,
        target : target
    }
}