
export const apiEndPoint = "https://europe-west1-andreasorrentino-fbac1.cloudfunctions.net/widgets/";

export const apiMethods = {
    getMedias : 'getMedias',
    sendEmail : 'sendEmail'
};

export const categories = {
    costumes : 'costumes',
    mhd : 'mhd',
    sketches : 'sketches'
}