import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { IntlProvider, addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import itLocaleData from "react-intl/locale-data/it";

import Layout from './hoc/Layout/Layout';
import TransitionWrapper from './hoc/TransitionWrapper/TransitionWrapper';
import Spinner from './components/UI/Spinner/Spinner';
import translations from "./languages/locales";

addLocaleData(enLocaleData);
addLocaleData(itLocaleData);

//#region Lazy Loading Section

const landingPromise = import("./containers/Landing/Landing");
const Landing = React.lazy(() => landingPromise);

// const Landing = React.lazy(() => {
//   return import('./containers/Landing/Landing');
// });

const Collection = React.lazy(() => {
    return import('./containers/Collection/Collection');
});

const Videos = React.lazy(() => {
    return import('./containers/Videos/Videos');
  });


//#endregion End of Lazy Loading Section


const app = props => {
  
    let routes = null;
    let wrappedRoutes = null;
      
    routes = (
        <Switch>
            <Route path="/" exact render={props => <Landing {...props}/>} />
            <Route path="/costumes" render={props => <Collection {...props}/>} />
            <Route path="/mhd" render={props => <Collection {...props}/>} />
            <Route path="/sketches" render={props => <Collection {...props}/>} />
            <Route path="/videos" render={props => <Videos {...props}/>} />
            <Redirect to="/" />
        </Switch>
    );

    wrappedRoutes= (
        <Layout>
            <TransitionWrapper location={props.location}>
                <Suspense fallback={<Spinner />}>{routes}</Suspense>
            </TransitionWrapper>
        </Layout>
    );

      // get locale from url : window.location.search.replace("?locale=","") 
    const locale = props.language;
    const messages = translations[locale];

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
          {wrappedRoutes}
      </IntlProvider>
    );
}

const mapStateToProps = state => {
    return {
        language: state.main.language
    }
}

export default connect ( mapStateToProps )( withRouter( app ));
