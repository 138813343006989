import React, { useState, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import classes from './Layout.css';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import ToTopButton from '../../components/UI/Button/ToTopButton/ToTopButton';

export const LabelsContext = React.createContext();

const Layout = props => {

    const labels = defineMessages({
        costumes:{
            id: 'layout.labels.costumes',
            defaultMessage: 'Costumes'
        },
        mhd:{
            id: 'layout.labels.mhd',
            defaultMessage: 'Make Up and Hair Design'
        },
        sketches:{
            id: 'layout.labels.sketches',
            defaultMessage: 'Sketches'
        },
        showcase:{
            id: 'layout.labels.showcase',
            defaultMessage: 'Showcase'
        },
        showreel:{
            id: 'layout.labels.showreel',
            defaultMessage: 'Showreel'
        },
        videos:{
            id: 'layout.labels.videos',
            defaultMessage: 'Videos'
        },
    });

    const menuOnly = defineMessages({
        landing:{
            id: 'layout.menu.landing',
            defaultMessage: 'Home'
        },
        about:{
            id: 'layout.menu.about',
            defaultMessage: 'About'
        },
        
        contact:{
            id: 'navigationItem.contact',
            defaultMessage: 'Contact'
        },
    });

    useEffect(() => {
        props.onGetMediasList();
    }, []);

    const [showMobileMenu, setShowMobileMenu] = useState( false );

    const onDrawerToggle = () => {
        setShowMobileMenu( !showMobileMenu );
    }

    return (
        <div className={classes.Layout}>

            <Toolbar
                clicked={onDrawerToggle}
                open={showMobileMenu}/>
                
            <SideDrawer
                messages={{...menuOnly, ...labels}}
                color={props.textColor}
                open={showMobileMenu}
                closed={onDrawerToggle} />
            
            <ToTopButton 
                className={classes.ToTopButton} 
                activeClassName={classes.ToTopButtonActive}
                limit={100}/>

            <main className={classes.Content}>
                <LabelsContext.Provider value={labels}>
                    {props.children}
                </LabelsContext.Provider>
            </main>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetMediasList : () => dispatch( actions.getMediasLinks() )
    }
}

export default  connect(null, mapDispatchToProps)( withRouter ( Layout ));