import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import { scrollTo } from '../shared/utility';


const scrollToComponent = props =>{

    const activeLinkHandler = () => {
        const location = `${window.location.pathname}${window.location.hash}`;
        const withoutTag = props.element.replace('#', '');
        if ( props.element === location || withoutTag === location ) return true;

        return false;

    }

    const functionHandler = () => {
      if (props.clickHandler) props.clickHandler();
      if (props.callBack) props.callBack();
    }

    let navlink = null;

    switch (props.type) {
      case "function" : {
          if (props.clickHandler) {
              navlink = ( 
                  <span   className={props.className} 
                          onClick={functionHandler}
                          style={{...props.style, cursor: "pointer"}}>
                      {props.children}
                  </span>
              );
          }
          break;
      }
      case "external" : {
          navlink = ( 
              <a      className={props.className}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={props.element}
                      onClick={functionHandler}
                      style={props.style}>
                  {props.children}
              </a>
          );
          break;
      }
      default: {
          navlink = (
            <NavLink  className={props.className}
                      activeClassName={props.active}
                      to={props.element}
                      location={{pathname: props.element}}
                      isActive={activeLinkHandler}
                      onClick={(event) => {
                                            scrollTo(event, props.element, props.offset ? props.offset :  70, props.toTop);
                                            functionHandler();
                                          }
                      }
                      style={props.style}>

                  {props.children}
          </NavLink>
          );
          break;
      }
      
    }

    return navlink;
    
};


export default withRouter( scrollToComponent );

