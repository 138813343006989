import React, { useState } from 'react';

import CustomButton from '../CustomButton/CustomButton';
import ScrollTo from '../../../../hoc/scrollToComponent';
import ScrollHandler from '../../../../hoc/ScrollHandler';

const ToTopButton = props => {

    const [scrolled, setScrolled] = useState( false );

    const scrollHandler = () => {
        if (window.pageYOffset > props.limit) {
            if (!scrolled) setScrolled( true );
        } else {
            setScrolled( false );
        }
    }

    let attachedClasses = [props.className];

    if (scrolled && !attachedClasses.includes(props.activeClassName)) {
        attachedClasses.push(props.activeClassName);
    }

    return (
        <ScrollHandler onWindowScroll={scrollHandler}>
            <ScrollTo className={attachedClasses.join(' ')} element={window.location.pathname} toTop>
                <CustomButton >
                    {/* <Icon name={'arrow up'} /> */}
                    &#8593;
                </CustomButton>
            </ScrollTo>
        </ScrollHandler>
    )
};

export default ToTopButton