import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Flag } from 'semantic-ui-react';

import * as actions from '../../../../store/actions';

const languageOptions = [
    { key: 'en', text: 'English', value: 'en', flag: 'gb' },
    { key: 'it', text: 'Italiano', value: 'it', flag: 'it' },
  ]


    
const Language = props => {

    const [language, setLanguage] = useState(props.language);

    const setLanguageHandler = ( event, data ) => {
        props.onSetLanguage( data.value );
    }

    const trigger = (
        <span>
            <Flag name={languageOptions.find(l => l.value === language).flag} className={props.className}/>
            {languageOptions.find(l => l.value === language).text}
        </span>
    )

    return (
        <Dropdown
            trigger={trigger}
            pointing='bottom left'
            icon={null}
            onChange={setLanguageHandler}
            value={language}
            options={languageOptions}
            />
    );
}

const mapStateToprops = state => {
    return {
        language : state.main.language
    }
}

const mapDispatchToprops = dispatch => {
    return {
        onSetLanguage : ( language ) => dispatch( actions.setLanguage( language ))
    }
}

export default connect(mapStateToprops, mapDispatchToprops)( Language ); 