exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Toolbar__Toolbar__2JJW- {\n    display: none;\n}\n\n@media (max-width: 1440px) {\n    .Toolbar__DesktopOnly__WADUd {\n        display: none;\n    }\n\n    .Toolbar__Toolbar__2JJW- {\n        height: 56px;\n        width: 100%;\n        position: fixed;\n        top: 0;\n        left: 0;\n        background-color: transparent;\n        box-sizing: border-box;\n        z-index: 399;\n        -webkit-transition: all 0.3s ease-out;\n        transition: all 0.3s ease-out;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n    }\n    \n    .Toolbar__LeftItems__2FM5d {\n        margin-left: 20px;\n        margin-right: auto;\n        display: flex;\n        align-items: center;\n    }\n\n    .Toolbar__DrawerToggle__2le1I {\n        stroke: white;\n        margin-left: 20px;\n    }\n\n    .Toolbar__bgBlack__Wwvb1{\n        background-color: rgba(0,0,0,0.9);\n    }\n\n    .Toolbar__bgBlack__Wwvb1 .Toolbar__Logo__1efBD img {\n        height: 40px;\n        -webkit-transition: all 0.3s ease-out;\n        transition: all 0.3s ease-out;\n    }\n    \n    .Toolbar__Logo__1efBD {\n        position: absolute;\n        top: 5px ;\n        left: 50%;\n        z-index: 1000;\n        -webkit-transform: translateX(-50%);\n                transform: translateX(-50%);\n    }\n    \n    .Toolbar__Logo__1efBD img {\n        height: 80px ;\n        -webkit-transition: all 0.3s ease-out;\n        transition: all 0.3s ease-out;\n    }\n    \n}\n\n@media (max-width:549px){\n    .Toolbar__Logo__1efBD img {\n        height: 40px;\n    }\n\n    .Toolbar__LeftItems__2FM5d {\n        margin-left: 0;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Toolbar": "Toolbar__Toolbar__2JJW-",
	"DesktopOnly": "Toolbar__DesktopOnly__WADUd",
	"LeftItems": "Toolbar__LeftItems__2FM5d",
	"DrawerToggle": "Toolbar__DrawerToggle__2le1I",
	"bgBlack": "Toolbar__bgBlack__Wwvb1",
	"Logo": "Toolbar__Logo__1efBD"
};