import React, { useState } from 'react';

import classes from './Toolbar.css';
import Logo from '../../../assets/images/Logo.png';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import ScrollHandler from '../../../hoc/ScrollHandler';
import ScrollTo from '../../../hoc/scrollToComponent';


const Toolbar = props => {

    const [scrolled, setScrolled] = useState( false );

    const scrollHandler = () => {
        if (window.pageYOffset > 30) {
            if (!scrolled) setScrolled( true );
        } else {
            setScrolled( false );
        }
    }

    let attachedClasses = [classes.Toolbar];

    if (scrolled && !attachedClasses.includes(classes.bgBlack)) {
        attachedClasses.push(classes.bgBlack);
    }


    return (
        <ScrollHandler onWindowScroll={scrollHandler}>
            <header className={attachedClasses.join(' ')}>
                <ScrollTo element={window.location.pathname} toTop className={classes.Logo}>
                    <img src={Logo} alt=''/>
                </ScrollTo>

                <div className={classes.LeftItems}>
                    
                    <DrawerToggle   
                        className={classes.DrawerToggle}
                        open={props.open}
                        clicked={props.clicked}/>
                </div>

            </header>
        </ScrollHandler>
    );
}

export default Toolbar;