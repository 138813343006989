import React from 'react';
import Svg from 'react-inlinesvg';

import classes from './Social.css';

import email from '../../assets/images/email.svg';
import phone from '../../assets/images/phone.svg';

const Social = props => {

    let image = null

    switch (props.type){
        case 'email' : {
            image = email;
            break;
        }
        case 'phone' : {
            image = phone;
            break;
        }
        default : break;
    }

    const item = (
        image
            ?   <div className={classes.SocialItem}>
                    <Svg src={image} />
                    {props.children}
                </div>
            : null
    );

    return item;
}

export default Social;