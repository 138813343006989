import { updateObject } from '../../../shared/utility';

export const successHandler = ( state, action ) => {
    const updatedError = updateObject(state.error, { [action.target] : null });
    const updatedLoading = updateObject(state.loading, { [action.target] : false });   
    return updateObject( state, {[action.target] : action.responseData, error: updatedError, loading: updatedLoading });
}

export const startedHandler = ( state, action ) => {
    const updatedError = updateObject(state.error, { [action.target] : null });
    const updatedLoading = updateObject(state.loading, { [action.target] : true });
    return updateObject( state, { error: updatedError, loading: updatedLoading } );
}

export const failureHandler = ( state, action ) => {
    const updatedError = updateObject(state.error, { [action.target] : action.error });
    const updatedLoading = updateObject(state.loading, { [action.target] : false });
    return updateObject( state, { error: updatedError, loading: updatedLoading } );
}