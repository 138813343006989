import React from 'react';

import ScrollTo from '../../../../hoc/scrollToComponent';
import classes from './NavigationItem.css';


const NavigationItem = ( props ) => {

    const line = (
        <span className={classes.Line}></span>
    );

    const text = (
        <span className={classes.Text}>{props.children}</span>
    );

    return (
        <li className={classes.NavigationItem} onClick={props.callBack}>
            <ScrollTo
                    className={classes.Link}
                    type={props.type}
                    toTop={props.toTop}
                    element={props.link}
                    exact={props.exact}
                    fixed={props.fixed}
                    offset={props.offset}
                    clickHandler={props.clickHandler}
                    active={classes.Active}
                    callBack={props.callBack}
            >
                {text}
                {line}
            </ScrollTo>
        </li>
    );

};

export default NavigationItem;