import { apiMethods } from '../../shared/appConfig';
import * as axiosActions from './helpers/axiosMethods';

import * as actions from '../actions';

export function* sendEmailSaga( payload ) {
    yield axiosActions.post(
        apiMethods.sendEmail,
        "email",
        actions.sendEmailStarted,
        actions.sendEmailSuccess,
        actions.sendEmailFailure,
        payload.data);
}