import React from 'react';
import { injectIntl } from 'react-intl';

import classes from './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { categories } from '../../../shared/appConfig';

const NavigationItems = ( props ) => {

    const {intl: { formatMessage }, messages} = props;

    return (
        <ul className={classes.NavigationItems}>
        <NavigationItem callBack={props.callBack} link="/" exact toTop fixed >{formatMessage(messages.landing)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link="/#about" fixed >{formatMessage(messages.about)}</NavigationItem>
        {/* <NavigationItem callBack={props.callBack} link="/#showcase" fixed >{formatMessage(messages.showcase)}</NavigationItem> */}
        <NavigationItem callBack={props.callBack} link={"/#" + categories.costumes} fixed offset={100} >{formatMessage(messages.costumes)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link={"/#" + categories.mhd} fixed offset={100} >{formatMessage(messages.mhd)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link={"/#" + categories.sketches} fixed offset={100} >{formatMessage(messages.sketches)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link={"/#showreel"} fixed >{formatMessage(messages.showreel)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link="/videos" fixed >{formatMessage(messages.videos)}</NavigationItem>
        <NavigationItem callBack={props.callBack} link="/#contact" fixed>{formatMessage(messages.contact)}</NavigationItem>
    </ul>
    );
}



export default injectIntl( NavigationItems );