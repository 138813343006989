import React from 'react';

import CloseButton from '../../../UI/Button/CloseButton/CloseButton';

const DrawerToggle = (props) => ( 
        <CloseButton 
                    className={props.className}
                    cross={props.open} 
                    clicked={props.clicked}/>

);

export default DrawerToggle;