import React from 'react';

import classes from './CustomButton.css';

const CustomButton = props => (
    <div className={[classes.Button, props.className].join(' ')} style={props.style} onClick={props.onClick}>
        {props.children}
    </div>
);

export default CustomButton;