import { takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import {
  sendEmailSaga
} from './main'

import {
    getMediasListSaga,
} from "./media";


export function* watcher() {

  yield takeEvery(actionTypes.SEND_EMAIL, sendEmailSaga);
  yield takeEvery(actionTypes.GET_MEDIAS_LIST, getMediasListSaga);

}