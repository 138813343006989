import { apiMethods } from '../../shared/appConfig';
import * as axiosActions from './helpers/axiosMethods';

import * as actions from '../actions';

export function* getMediasListSaga() {
    yield axiosActions.get(
        apiMethods.getMedias,
        "medias",
        actions.getMediasStarted,
        actions.getMediasSuccess,
        actions.getMediasFailure);
}

