exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* .NavigationItem {\n    \n    \n    \n    width: 100%;\n    font-weight: 400;\n    font-size: 18px;\n    padding : 5px 0;\n    \n} */\n\n.NavigationItem__NavigationItem__23bcu {\n    padding: 5px 10px;\n    display: flex;\n    height: 100%;\n    width: auto;\n    align-items: center;\n    font-size: 18px;\n    -webkit-transition: all .3s ease-out;\n    transition: all .3s ease-out;\n    box-sizing: border-box;\n    margin: 5px 0;\n    display: block;\n}\n\n.NavigationItem__NavigationItem__23bcu .NavigationItem__Link__1foCc {\n    text-decoration: none;\n    width: 100%;\n    box-sizing: border-box;\n    display: block;\n    position: relative;\n    color: inherit;\n    padding: 0 30px; \n}\n\n.NavigationItem__NavigationItem__23bcu .NavigationItem__Icon__2r2eX {\n    display: none;\n}\n\n@media (min-width: 550px) {\n    \n    .NavigationItem__NavigationItem__23bcu {\n        padding: 15px 10px;\n    }\n\n    .NavigationItem__NavigationItem__23bcu .NavigationItem__Link__1foCc {\n        cursor: pointer;\n    }\n\n    .NavigationItem__NavigationItem__23bcu .NavigationItem__Link__1foCc:hover {\n        color: #282725;\n        -webkit-transition: all .3s ease-out;\n        transition: all .3s ease-out;\n    }\n\n    .NavigationItem__Active__3rUd4 {\n        color: #282725 !important;\n        -webkit-transition: all .3s ease-out;\n        transition: all .3s ease-out;\n    }\n\n\n}", ""]);

// Exports
exports.locals = {
	"NavigationItem": "NavigationItem__NavigationItem__23bcu",
	"Link": "NavigationItem__Link__1foCc",
	"Icon": "NavigationItem__Icon__2r2eX",
	"Active": "NavigationItem__Active__3rUd4"
};